@font-face {
  font-family: 'Herculanum';
  src: url('../fonts/Herculanum\ Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Nihat';
  src: url('../fonts/nihat.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #000;
  color: #fff;
  font-family: 'Herculanum',Arial, sans-serif;
}

/* Header */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: #000;
  z-index: 1000;
  text-align: center;
}

nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
}

nav ul li {
  position: relative;
  margin: 0 1rem;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

nav p {
  font-family: 'Nihat',Arial, sans-serif;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0rem;
}

nav a {
  text-decoration: none;
  color: #ff0000;
}

.chat-container {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  background-color: #000;
  padding: 1rem;
  z-index: 1001;
}

.chat-input {
  width: 200px;
  padding: 0.5rem;
  background-color: #222;
  color: #fff;
  border: none;
  font-family: 'Herculanum',Arial, sans-serif;
}

.chat-result {
  display: none;
  width: 200px;
  background-color: #222;
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  font-family: 'Herculanum',Arial, sans-serif;
}

.flash-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 72px;
  background-color: #000;
  color: #fff;
  z-index: 9999;
}

.flash-message-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999;
}

.flash-message-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Hero section */
.hero {
  text-align: center;
  padding: 1rem 2rem;
  margin-top: 0.3rem;
}

.hero-logo {
  max-width: 50%;
  height: auto;
  margin-bottom: 1rem;
}

/* Intro section */
.intro {
  text-align: center;
  padding: 2rem;
  position: relative;
  font-family: 'Nihat',Arial, sans-serif;
}

.intro h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-family: 'Herculanum',Arial, sans-serif;
}

.highlight {
  display: inline;
  background-color: rgb(26, 39, 39);
  color: rgb(26, 39, 39);
  transition: color 0.3s;
}

.highlight:hover {
  color: #fff;
}

.quote {
  position: absolute;
  left: 65%;
  transform: translateX(-50%);
  margin-top: 1.2rem;
  font-size: 0.8rem;
  white-space: nowrap;
  display: inline;
  background-color: rgb(26, 39, 39);
  color: rgb(26, 39, 39);
  transition: color 0.3s;
}

.quote:hover {
  color: #fff;
}

.soap-image {
  display: block;
  margin: 2rem auto;
  max-width: 28%;
  height: auto;
}

.intro-image {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 13%;
  transform: rotate(88deg);
  margin-top: -6%;
}

.intro-l-image {
  position: absolute;
  top: 13rem;
  left: 3.3rem;
  width: 13%;
  transform: rotate(-33deg);
  margin-top: -6%;
}

/* Story section */
.story {
  margin-top: 1rem;
  padding: 2rem;
  position: relative;
}

.story h2 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.story-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  border: 4px solid;
  border-image: linear-gradient(to right, #fc0303, #7d0101) 1;
}

.story-content p {
  margin-bottom: 1rem;
  font-family: 'Nihat',Arial, sans-serif;
}

.story-image {
  position: relative;
  bottom: 20px;
  left: 3%;
  transform: rotate(-27deg);
  width: 16%;
  height: auto;
  margin-top: -8%;
}

.story-l-image {
  position: absolute;
  top: 23rem;
  right: 3.3rem;
  width: 13%;
  transform: rotate(33deg);
  margin-top: -6%;
}

.story-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.story-navigation button {
  padding: 0.5rem 1rem;
  background-color: #fc0303;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Herculanum', Arial, sans-serif;
  font-size: 1.3rem;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.story-navigation button:disabled {
  background-color: #4a2d2d;
  cursor: not-allowed;
  font-family: 'Herculanum', Arial, sans-serif;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Economics section */
.economics {
  display: flex;
  text-align: center;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  margin-top: 0.3rem;
  align-items: center;
  flex-direction: column;
}

.economics img {
  position: relative;
  z-index: 1;
  max-width: 40%;
}

.economics-table {
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
}

.economics-table td {
  padding: 1rem;
  border: 2px solid #fc0303;
  vertical-align: middle;
}

.economics-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.economics-title {
  font-size: 1.6rem;
  color: #888;
  margin-bottom: 0.5rem;
}

.economics-value {
  font-size: 2.5rem;
}

.product-image {
  max-width: 2.5rem;
  max-height: auto;
  object-fit: contain;
}

/* Buy Instructions section */
.buy-instructions {
  text-align: left;
  padding: 2rem;
}

.buy-instructions h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align: center;
}

.buy-instructions ol {
  padding-left: 1.5rem;
}

.buy-instructions li {
  margin-bottom: 1rem;
}

.buy-instructions h3 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.buy-instructions p {
  font-family: 'Nihat', Arial, sans-serif;
  margin-bottom: 0.5rem;
}

.buy-instructions ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.buy-instructions a {
  color: #fff;
  text-decoration: underline;
}

/* FAQ section */
.faq {
  max-width: 800px;
  margin: 0 auto;
  padding: 3.5rem;
  margin-top: 0.3rem;
}

.faq h2 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.faq-item {
  margin-bottom: 2rem;
}

.faq-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.faq-item p {
  line-height: 1.5;
  font-family: 'Nihat',Arial, sans-serif;
}

.faq-images {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.faq-image {
  width: 30%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
}

.faq-image-left {
  position: absolute;
  transform: rotate(-10deg);
  top: 0;
  left: 0;
  margin-left: -8%;
  margin-top: 47%;
  scale: 80%;
}

.faq-image-right {
  position: absolute;
  transform: rotate(13deg);
  bottom: 0;
  right: 0;
  margin-right: -10%;
  margin-bottom: -13%;
  scale: 68%;
}

.faq-l-image-right {
  position: absolute;
  transform: rotate(-23deg);
  top: 0;
  right: 0;
  margin-right: -6%;
  margin-top: 70%;
  scale: 96%;
}

.faq-l-image-left {
  position: absolute;
  transform: rotate(30deg);
  bottom: 0;
  left: 0;
  margin-left: -1%;
  margin-bottom: 3%;
  scale: 73%;
}

/* Buy section */
.buy {
  padding: 2rem;
  text-align: center;
}

.buy h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.buy-link {
  display: inline-block;
}

.buy-image {
  max-width: 30%;
  height: auto;
  transition: transform 0.3s ease;
}

.buy-image:hover {
  transform: scale(1.05);
}

.dexscreener-embed {
  position: relative;
  width: 100%;
  height: 5rem;
}

.dexscreener-embed iframe {
  position: relative;
  width: 100%;
  height: 800%;
}

/* Meme Display section */
.meme-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  position: relative;
  min-height: 100vh;
  margin-top: 0.8rem;
}

.meme-display h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.meme-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 33%;
  max-height: 66vh;
  margin-bottom: 1rem;
}

.meme-container img {
  max-width: 100%;
  max-height: calc(60vh - 50px);
  object-fit: contain;
  margin-bottom: 1rem;
}

.meme-display button {
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem 2rem;
  background-color: #fc0303;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Herculanum', Arial, sans-serif;
  font-size: 2rem;
  border-radius: 50px;
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.meme-credit {
  font-size: 0.8rem;
  color: #888;
  text-align: center;
  margin-top: 0rem;
}

/* Our Team section */
.our-team {
  text-align: center;
  padding: 2rem;
  margin-top: 1.3rem;
}

.our-team h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.team-members {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.team-member {
  width: 45%;
  margin-bottom: 2rem;
}

.team-member img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.team-member h3 {
  font-size: 2.3rem;
  margin-bottom: 0.5rem;
}

.team-member p {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: 'Nihat',Arial, sans-serif;
}

/* Progress Bar */
.token-balance-progress-bar {
  width: 100%;
  height: auto;
  padding: 4rem;
  margin-top: -4rem;
}

.token-balance-progress-bar h2 {
  text-align: center;
  padding: 2rem;
  font-size: 3rem;
  margin-bottom: 0rem;
}

.token-balance-progress-bar p {
  font-family: 'Nihat',Arial, sans-serif;
  text-align: center;
  padding: 1rem;
}

.token-balance-progress-bar a {
  text-decoration: none;
  color: #fff;
}

.progress-bar-container {
  position: relative;
  width: 80%;
  height: 2rem;
  background-color: #860303;
  border-radius: 13px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10%;
}

.progress-bar-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #979797;
  border-radius: 13px;
}

.benchmark {
  position: absolute;
  transform: translateX(-50%);
  text-align: center;
  width: 1rem;
}

.benchmark-label {
  font-size: 1.2rem;
  white-space: nowrap;
  margin-top: -1.3rem;
}

.benchmark-line {
  width: 2.3px;
  height: 3rem;
  background-color: #a00000;
  margin-top: -0.5rem;
}

.benchmark-value {
  font-size: 1.2rem;
  margin-bottom: -1rem;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #fa0202;
  transition: width 0.5s ease;
  border-radius: 13px;
}

.progress-image {
  position: absolute;
  top: 5%;
  left: 100%;
  margin-left: -1rem;
  width: 2rem;
}

/* Footer */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 1rem;
  background-color: #000;
  z-index: 1000;
}

footer nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
}

footer nav ul li {
  margin: 0 1rem;
}

footer nav ul li a {
  color: #fff;
  text-decoration: none;
}

main {
  padding-top: 30px; /* Adjust this value based on the height of your header */
  padding-bottom: 30px; /* Adjust this value based on the height of your footer */
}

/* Sections */
section {
  scroll-margin-top: 5vh;
}



/* Media query for mobile devices */
@media screen and (max-width: 600px) {
  /* Mobile Header */
  header {
    padding: 0.3rem;
    font-size: smaller;
  }

  nav ul li {
    margin: 0.3rem;
  }

  .chat-input,
  .chat-result {
    font-size: 0.8rem;
  }

  .flash-message {
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 33px;
    background-color: #000;
    color: #fff;
    z-index: 9999;
  }

  .flash-message-video {
    position: fixed;
    top: 30%;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    z-index: 9999;
  }
  
  .flash-message-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    object-fit: cover;
  }

  /* Mobile Hero section */
  .hero {
    margin-top: 1rem;
    padding: 0.8rem;
  }

  .hero-logo {
    max-width: 100%;
  }

  /* Mobile Intro section */
  .intro {
    padding: 0.4rem;
  }

  .intro h2 {
    font-size: 1.2rem;
  }

  .quote {
    font-size: 0.3rem;
  }

  .soap-image {
    max-width: 50%;
  }

  .intro-image,
  .intro-l-image {
    display: none;
  }

  /* Mobile Story section */
  .story {
    padding: 0.4rem;
  }

  .story h2 {
    font-size: 1.2rem;
  }

  .story-content {
    padding: 0.4rem;
  }

  .story-navigation button,
  .story-navigation button:disabled {
    font-size: 0.6rem;
    width: 80px;
    height: 25px;
  }

  .story-image,
  .story-l-image {
    display: none;
  }

  /* Mobile Economics section */
  .economics {
    padding: 0.8rem;
    margin-top: 2rem;
  }

  .economics img {
    max-width: 100%;
  }

  .economics-title {
    font-size: 0.6rem;
  }

  .economics-value {
    font-size: 0.8rem;
  }

  .product-image {
    max-width: 1.5rem;
  }

  .coin {
    display: none;
  }

/* Mobile Buy Instructions section */
.buy-instructions h2 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.buy-instructions ol {
  padding-left: 1.5rem;
}

.buy-instructions li {
  margin-bottom: 0.6rem;
}

.buy-instructions h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.buy-instructions p {
  font-family: 'Nihat', Arial, sans-serif;
  margin-bottom: 0.5rem;
  font-size: 0.6rem;
}

.buy-instructions ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.buy-instructions a {
  color: #fff;
  text-decoration: underline;
  font-size: 0.8rem;
}

  /* Mobile FAQ section */
  .faq {
    margin-top: 3rem;
    padding: 0.8rem;
  }

  .faq h2 {
    font-size: 1.2rem;
  }

  .faq-item h3 {
    font-size: 1rem;
  }

  .faq-item {
    font-size: 0.8rem;
  }

  .faq-image-left,
  .faq-image-right,
  .faq-l-image-right,
  .faq-l-image-left {
    display: none;
  }

  /* Mobile Progress Bar */
.token-balance-progress-bar {
  width: 100%;
  height: auto;
  padding: 4rem;
  margin-top: -4rem;
}

.token-balance-progress-bar h2 {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  margin-bottom: 0rem;
}

.token-balance-progress-bar p {
  font-size: 0.6rem;
  margin-top: -1rem;
}

.progress-bar-container {
  position: relative;
  width: 96%;
  height: 0.8rem;
  background-color: #860303;
  border-radius: 13px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 2%;
}

.progress-bar-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #979797;
  border-radius: 13px;
}

.benchmark {
  position: absolute;
  transform: translateX(-50%);
  text-align: center;
  width: 1rem;
}

.benchmark-label {
  font-size: 0.6rem;
  white-space: nowrap;
  margin-top: -0.8rem;
}

.benchmark-line {
  width: 2.3px;
  height: 1rem;
  background-color: #a00000;
  margin-top: 0.1rem;
}

.benchmark-value {
  font-size: 0.6rem;
  margin-bottom: -1.2rem;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #fa0202;
  transition: width 0.5s ease;
  border-radius: 13px;
}

.progress-image {
  position: absolute;
  top: 2.5%;
  left: 100%;
  margin-left: -0.5rem;
  width: 1rem;
}

  /* Mobile Buy section */
  .buy {
    margin-top: -3rem;
    padding: 0.8rem;
  }

  .buy h2 {
    font-size: 1.2rem;
  }

  .buy-image {
    max-width: 50%;
  }

  /* Mobile Meme Display section */
  .meme-display {
    margin-top: 3rem;
    padding: 0.8rem;
  }

  .meme-display h2 {
    font-size: 1.2rem;
  }

  .meme-container {
    max-height: 80%;
    max-width: 100%;
  }

  .meme-display button {
    bottom: 23%;
    font-size: 1.2rem;
    width: 160px;
    height: 30px;
  }

  .meme-credit {
    font-size: 0.6rem;
  }

  /* Mobile Our Team section */
  .our-team {
    margin-top: 3rem;
    padding: 0.8rem;
  }

  .our-team h2 {
    font-size: 1.3rem;
  }

  .team-member h3 {
    font-size: 1rem;
  }

  .team-member p {
    font-size: 0.8rem;
  }

  /* Mobile Footer */
  footer {
    font-size: smaller;
    padding: 0.3rem;
  }

  footer nav ul li {
    margin: 0.3rem;
  }

  main {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

/* Media query for tablet devices */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  /* Tablet Header */
  header {
    padding: 0.3rem;
    font-size: 1.6rem;
  }

  nav ul li {
    margin: 1rem;
  }

  .chat-input,
  .chat-result {
    font-size: 0.8rem;
  }

  /* Tablet Hero section */
  .hero {
    margin-top: 1.3rem;
    padding: 0.8rem;
  }

  .hero-logo {
    max-width: 80%;
  }

  /* Tablet Intro section */
  .intro {
    padding: 0.6rem;
  }

  .intro h2 {
    font-size: 1.6rem;
  }

  .quote {
    font-size: 0.8rem;
  }

  .soap-image {
    max-width: 40%;
  }

  /* Tablet Story section */
  .story {
    padding: 0.6rem;
  }

  .story h2 {
    font-size: 1.6rem;
  }

  .story-content {
    padding: 0.8rem;
  }

  .story-navigation button,
  .story-navigation button:disabled {
    font-size: 0.8rem;
    width: 100px;
    height: 30px;
  }

  .story-image,
  .story-l-image {
    display: none;
  }

  /* Tablet Economics section */
  .economics {
    padding: 0.8rem;
    margin-top: 5rem;
    align-items: center;
  }

  .economics img {
    max-width: 60%;
  }

  .economics-title {
    font-size: 1.2rem;
  }

  .economics-value {
    font-size: 1.6rem;
  }

  .product-image {
    max-width: 1.5rem;
  }

  .coin {
    display: none;
  }

  /* Tablet FAQ section */
  .faq {
    margin-top: 6rem;
    padding: 0.8rem;
  }

  .faq h2 {
    font-size: 1.6rem;
  }

  .faq-item h3 {
    font-size: 1.2rem;
  }

  .faq-item {
    font-size: 1rem;
  }

  .faq-image-left,
  .faq-image-right,
  .faq-l-image-right {
    scale: 30%;
  }

  .faq-l-image-left {
    scale: 30%;
    margin-left: -12%;
  }

  /* Mobile Progress Bar */
  .token-balance-progress-bar {
    width: 100%;
    height: auto;
    padding: 4rem;
    margin-top: -4rem;
  }
  
  .token-balance-progress-bar h2 {
    text-align: center;
    padding: 2rem;
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
  }

  .token-balance-progress-bar p {
    font-size: 0.8rem;
    margin-top: -0.5rem;
  }

  .progress-bar-container {
    position: relative;
    width: 88%;
    height: 1.3rem;
    background-color: #860303;
    border-radius: 13px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 6%;
  }
  
  .progress-bar-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #979797;
    border-radius: 13px;
  }
  
  .benchmark {
    position: absolute;
    transform: translateX(-50%);
    text-align: center;
    width: 1rem;
  }
  
  .benchmark-label {
    font-size: 0.8rem;
    white-space: nowrap;
    margin-top: -1.1rem;
  }
  
  .benchmark-line {
    width: 2.3px;
    height: 2rem;
    background-color: #a00000;
    margin-top: 0.1rem;
  }
  
  .benchmark-value {
    font-size: 0.8rem;
    margin-bottom: -1.4rem;
  }
  
  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #fa0202;
    transition: width 0.5s ease;
    border-radius: 13px;
  }
  
  .progress-image {
    position: absolute;
    top: 1%;
    left: 100%;
    margin-left: -0.5rem;
    width: 1.5rem;
  }  

  /* Tablet Buy section */
  .buy {
    margin-top: -2.3rem;
    padding: 0.8rem;
  }

  .buy h2 {
    font-size: 1.6rem;
  }

  .buy-image {
    max-width: 50%;
  }

  /* Tablet Meme Display section */
  .meme-display {
    margin-top: 6rem;
    padding: 0.8rem;
  }

  .meme-display h2 {
    font-size: 1.6rem;
  }

  .meme-container {
    max-width: 100%;
    max-height: 80%;
  }

  .meme-display button {
    bottom: 23%;
    font-size: 1.8rem;
    width: 300px;
    height: 60px;
  }

  .meme-credit {
    font-size: 1rem;
  }

  /* Tablet Our Team section */
  .our-team {
    margin-top: 6rem;
    padding: 0.8rem;
  }

  .our-team h2 {
    font-size: 1.6rem;
  }

  .team-member h3 {
    font-size: 1.4rem;
  }

  .team-member p {
    font-size: 1.2rem;
  }

  /* Tablet Footer */
  footer {
    font-size: 1.6rem;
    padding: 0.3rem;
  }

  footer nav ul li {
    margin: 1rem;
  }

  main {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}


/* Jupiter Terminal */
.jupiter-terminal-container {
  min-height: 800px;
  height: 600px;
  width: 100%;
  max-width: 800px;
  border-radius: 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
  margin-top: 2.25rem;
}

@media (min-width: 1024px) {
  .jupiter-terminal-container {
    padding: 1rem;
  }
}

.jupiter-terminal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
}

@media (min-width: 1024px) {
  .jupiter-terminal-content {
    flex-direction: row;
  }
}

.jupiter-terminal-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jupiter-terminal-loading {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@media (min-width: 1024px) {
  .jupiter-terminal-loading {
    margin-top: 0;
    margin-left: 1rem;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

#integrated-terminal {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 384px;
  overflow: auto;
  justify-content: center;
  align-items: center;
  background-color: #282830;
  border-radius: 0.75rem;
}

#integrated-terminal.hidden {
  display: none;
}